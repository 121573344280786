import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_MODELS,
  GET_MODEL_DETAILS,
  CREATE_MODEL,
  UPDATE_MODEL,
  DELETE_MODEL,
} from "./actionTypes"
import {
  getModelsFail,
  getModelsSuccess,
  getModelDetailsSuccess,
  getModelDetailsFail,
  createModelFail,
  createModelSuccess,
  updateModelSuccess,
  updateModelFail,
  deleteModelSuccess,
  deleteModelFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Model - This line cannot be edited or removed
function getModelsAPi({ page, limit, searchText, brandId }) {
  console.log(brandId)
  return get(
    `/model/admin/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText || ""}&brand=${brandId ? brandId : ""}`
  )
}

const getModelDetailsAPi = modelId => {
  return get(`/model/admin/single/${modelId}`)
}

const createModelApi = ({ model }) => {
  return post("/model/admin/new", model)
}

const updateModelApi = ({ model, modelId }) => {
  return ApiPut(`/model/admin/${modelId}`, model)
}

const deleteModelApi = ({ modelId }) => {
  return del(`/model/admin/${modelId}`)
}

function* fetchModels({ payload }) {
  try {
    const response = yield call(getModelsAPi, payload)
    yield put(getModelsSuccess(response))
  } catch (error) {
    yield put(getModelsFail(error))
  }
}

function* fetchModelDetails({ payload: modelId }) {
  try {
    const response = yield call(getModelDetailsAPi, modelId)
    yield put(getModelDetailsSuccess(response))
  } catch (error) {
    yield put(getModelDetailsFail(error))
  }
}

function* onCreateModel({ payload }) {
  try {
    const response = yield call(createModelApi, payload)

    yield put(createModelSuccess(response))
    payload?.onCloseClick()
    doneNotification("Model Created Successfully!")
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createModelFail(error))
  }
}

function* onUpdateModel({ payload }) {
  try {
    const response = yield call(updateModelApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Model Updated Successfully!",
        title: "",
      })
      yield put(updateModelSuccess(response))
      if (payload?.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateModelFail(error.response.data))
  }
}

function* onDeleteModel({ payload: ModelId }) {
  try {
    const response = yield call(deleteModelApi, ModelId)
    yield put(deleteModelSuccess(response))
    Notification({
      type: "success",
      message: "Model Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteModelFail(error?.response?.data))
  }
}

function* modelSaga() {
  yield takeEvery(GET_MODELS, fetchModels)
  yield takeEvery(GET_MODEL_DETAILS, fetchModelDetails)
  yield takeEvery(CREATE_MODEL, onCreateModel)
  yield takeEvery(UPDATE_MODEL, onUpdateModel)
  yield takeEvery(DELETE_MODEL, onDeleteModel)
}

export default modelSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
